import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const linkStyle = {
  color: "rgb(69, 117, 221)",
  fontWeight: "bold",
};

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found"/>
    <h1>Page not found</h1>
    <p>
      Are you sure you typed the URL correctly? Try going to the{" "}
      <Link to={"/"} style={linkStyle}>
        home page
      </Link>
      .
    </p>
  </Layout>
);

export default NotFoundPage;
